<template>
  <section>
    <div v-if="blog">
      <div>
        <img
          class="w-full object-cover object-center h-96"
          :src="blog.cover_image"
          alt="Cover"
        />
      </div>
      <div class="container mx-auto px-4 md:px-0">
        <h1 class="font-bold text-2xl mt-6">{{ blog.title }}</h1>
        <p class="text-gray-400">
          {{ $format_date(blog.date_created) }} - Joemags
        </p>
        <section class="read-blog text-gray-50 prose prose-md my-4 mx-auto" v-html="blog.html"></section>
      </div>
    </div>
  </section>
</template>
<style src="./ReadBlog.css" scoped></style>
<script>
import { mapState } from "vuex";
import { store } from "~/store";
export default {
  name: "read-blog",
  data() {
    return {
      blog: null,
    };
  },
  mounted() {},
  computed: {
    ...mapState(["blogs"]),
  },

  watch: {
    blogs: {
      handler(val) {
        if (val && val.length) {
          const i = val.findIndex((blog) => blog.id === this.$route.params.id);
          this.blog = val[i];
          console.log(this.blog);
        }
      },
      immediate: true,
    },
  },
};
</script>
