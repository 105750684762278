<template>
  <section :class="view ? 'inner-view' : 'view'">
    <h2 class="w-full text-center font-bold text-xl tracking-wide mt-4 mb-6">
      My Thoughts
    </h2>
    <div
      class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3"
    >
      <article
        v-for="blog in blogs"
        :key="blog.id"
         @click="nav(blog)"
        class="transform hover:scale-x-105 transition duration-1000 relative my-4 mx-2 hover:shadow-md flex flex-col cursor-pointer"
      >
        <div class="h-64 overflow-hidden">
          <img
            alt="content"
            class="object-cover mx-1 h-full w-full transform hover:scale-125 transition duration-500"
            :src="blog.cover_image"
          />
        </div>
        <div class="px-5 py-2">
          <header class="font-bold text-lg focus:text-blue-700 line-clamp-2">
            {{blog.title}}
          </header>
          <p class="line-clamp-3 text-gray-400">
            {{blog.excerpt || ''}}
          </p>
          <button
            @click="nav(blog)"
            class="text-blue-700 py-4 rounded-md focus:outline-none transform hover:translate-x-5 transition duration-500"
          >
            Continue Reading
          </button>
        </div>
      </article>
    </div>
    <div class="w-full text-center">
      <button @click="load" class="my-4 transform transition duration-100 focus:outline-none px-4 py-2 font-semibold">
        <svg v-if="loading" class="animate-spin inline -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Load More
      </button>
    </div>
  </section>
</template>
<style src="./AppBlog.css" scoped></style>
<script>
import { mapState } from 'vuex';
// import { blogs } from '~/utils/firebase';
export default {
  props: ['view'],
  name: "app-blog",
  data() {
    return {
      loading: false,
    };
  },
  mounted(){
    // blogs.get()
    //   .then((snap) => {
    //     snap.forEach(doc => {
    //       console.log(doc.data());
    //       this.blogs.push(doc.data());
    //     });
    //   })
    //   .catch((err)=>{
    //     console.log(err);
    //   })
  },
  computed:{
    ...mapState(['blogs'])
  },
  methods: {
    load(){
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 3000);
    },
    nav({id}){
      this.$router.push({name: 'read_blog', params: {id}})
    }
  }
};
</script>
