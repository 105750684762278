<template>
  <section :class="view ? 'inner-view' : 'view'">
    <h2 class="w-full text-center font-bold text-2xl tracking-wide mt-4 mb-6">
      Skills acquired over 3+ years of experience.
    </h2>
    <div class="flex justify-between items-center flex-col sm:flex-row">
      <div class="w-80 mb-10 sm:mb-0">
        <div class="flex justify-center">
          <img src="../../assets/img/skills.svg" alt="Skills" class="w-20 sm:w-56">
        </div>
      </div>
      <div class="grid grid-rows-1 gap-5 flex-1">
        <div>
          <h1
            class="uppercase tracking-wide font-base flex justify-between mb-1"
          >
            <span>Backend Development</span>
            <span>90%</span>
          </h1>
          <div class="h-2.5 relative overflow-hidden">
            <div class="w-full h-full bg-gray-800 absolute"></div>
            <div class="h-full bg-blue-500 relative" style="width: 90%"></div>
          </div>
        </div>
        <div>
          <h1
            class="uppercase tracking-wide font-base flex justify-between mb-1"
          >
            <span>UI/UX Design</span>
            <span>95%</span>
          </h1>
          <div class="h-2.5 relative overflow-hidden">
            <div class="w-full h-full bg-gray-800 absolute"></div>
            <div class="h-full bg-blue-500 relative" style="width: 95%"></div>
          </div>
        </div>
        <div>
          <h1
            class="uppercase tracking-wide font-base flex justify-between mb-1"
          >
            <span>Mobile App Development</span>
            <span>80%</span>
          </h1>
          <div class="h-2.5 relative overflow-hidden">
            <div class="w-full h-full bg-gray-800 absolute"></div>
            <div class="h-full bg-blue-500 relative" style="width: 80%"></div>
          </div>
        </div>
        <div>
          <h1
            class="uppercase tracking-wide font-base flex justify-between mb-1"
          >
            <span>Modern Website Development</span>
            <span>99%</span>
          </h1>
          <div class="h-2.5 relative overflow-hidden">
            <div class="w-full h-full bg-gray-800 absolute"></div>
            <div class="h-full bg-blue-500 relative" style="width: 99%"></div>
          </div>
        </div>
        <div>
          <h1
            class="uppercase tracking-wide font-base flex justify-between mb-1"
          >
            <span>Chat Bots Development</span>
            <span>99%</span>
          </h1>
          <div class="h-2.5 relative overflow-hidden">
            <div class="w-full h-full bg-gray-800 absolute"></div>
            <div class="h-full bg-blue-500 relative" style="width: 99%"></div>
          </div>
        </div>
        <div>
          <h1
            class="uppercase tracking-wide font-base flex justify-between mb-1"
          >
            <span>Blockchain Technology</span>
            <span>70%</span>
          </h1>
          <div class="h-2.5 relative overflow-hidden">
            <div class="w-full h-full bg-gray-800 absolute"></div>
            <div class="h-full bg-blue-500 relative" style="width: 70%"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style src="./AppSkills.css" scoped></style>
<script>
export default {
  props: ["view"],
  name: "app-skills",
  data() {
    return {};
  },
};
</script>
