<template>
  <section class="h-screen grid items-center justify-center">
    <div class="text-center">
      <h2>Oops! Page Not Found :(</h2>
      <a href="/" class="text-blue-500 underline">Go Home</a>
    </div>

  </section>
</template>
<style src="./NotFound.css" scoped></style>
<script>
export default {
  name: "not-found",
};
</script>
