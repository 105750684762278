<template>
  <div class="w-full shadow-md select-none bg-dark-gray">
    <header class="container mx-auto flex justify-between  py-3 mt-1 px-2">
      <a @click="mobile_menu = true" href="javascript:;" class="sm:hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h8m-8 6h16"
          />
        </svg>
      </a>
      <div class="flex items-center">
        <router-link to="/" class="font-bold flex items-center">
          <img src="../../assets/img/logo.png" class="w-8" alt="Joemags" />
          <span class="font-bold text-xl ml-3">Joemags</span>
        </router-link>
        <nav class="hidden sm:flex sm:ml-4 sm:pl-4 sm:border-l border-gray-600">
          <router-link
            custom
            v-slot="{ href, navigate, isExactActive }"
            to="/tools"
          >
            <a
              :href="href"
              :class="
                isExactActive
                  ? 'apply-nav border-blue-700'
                  : 'apply-nav border-dark-gray hover:border-blue-700'
              "
              @click="navigate"
            >
              Tools
            </a>
          </router-link>

          <router-link
            custom
            v-slot="{ href, navigate, isExactActive }"
            to="/skills"
          >
            <a
              :href="href"
              :class="
                isExactActive
                  ? 'apply-nav border-blue-700'
                  : 'apply-nav border-dark-gray hover:border-blue-700'
              "
              @click="navigate"
            >
              Skills
            </a>
          </router-link>

          <router-link
            custom
            v-slot="{ href, navigate, isExactActive }"
            to="/work"
          >
            <a
              :href="href"
              :class="
                isExactActive
                  ? 'apply-nav border-blue-700'
                  : 'apply-nav border-dark-gray hover:border-blue-700'
              "
              @click="navigate"
            >
              Work
            </a>
          </router-link>
          <router-link
            custom
            v-slot="{ href, navigate, isExactActive }"
            to="/blog"
          >
            <a
              :href="href"
              :class="
                isExactActive
                  ? 'apply-nav border-blue-700'
                  : 'apply-nav border-dark-gray hover:border-blue-700'
              "
              @click="navigate"
            >
              Blog
            </a>
          </router-link>
        </nav>
      </div>
      <div class="w-8 sm:hidden"></div>
      <router-link custom v-slot="{ href, navigate }" :to="{ name: 'hire' }">
        <a
          :href="href"
          class="bg-blue-900 hidden focus:outline-none sm:inline px-5 py-2 rounded"
          @click="navigate"
        >
          Hire Me
        </a>
      </router-link>
    </header>
    <!-- Mobile -->
    <div
      v-if="mobile_menu"
      class="absolute px-7 py-8 bg-gray-900 inset-0 z-50 h-screen -mt-1"
    >
      <div class="w-full flex justify-between">
        <router-link to="/" class="font-bold flex items-center">
          <img src="../../assets/img/logo.png" class="w-10" alt="Joemags" />
          <span class="font-bold text-xl ml-3">Joemags</span>
        </router-link>
        <a
          @click="mobile_menu = false"
          href="javascript:;"
          class="rounded-full"
        >
          <svg
            class="w-10"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </a>
      </div>
      <div
        class="w-full text-lg grid grid-cols-1 place-content-between h-full -mt-4 pt-4"
      >
        <div class="grid grid-rows-1 text-center gap-3 py-2">
          <router-link custom v-slot="{ href, navigate }" to="/tools">
            <a :href="href" class="py-3" @click="navigate">
              Tools
            </a>
          </router-link>
          <router-link custom v-slot="{ href, navigate }" to="/skills">
            <a :href="href" class="py-3" @click="navigate">
              Skills
            </a>
          </router-link>
          <router-link custom v-slot="{ href, navigate }" to="/work">
            <a :href="href" class="py-3" @click="navigate">
              Work
            </a>
          </router-link>
          <router-link custom v-slot="{ href, navigate }" to="/blog">
            <a :href="href" class="py-3" @click="navigate">
              Blog
            </a>
          </router-link>
        </div>
        <router-link custom v-slot="{ href, navigate }" :to="{ name: 'hire' }">
          <a
            :href="href"
            class="mb-4 text-center focus:outline-none w-full bg-blue-900 rounded py-3"
            @click="navigate; mobile_menu = false"
          >
            Hire Me
          </a>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "nav-bar",
  data() {
    return {
      mobile_menu: false,
    };
  },
  mounted() {
    this.$router.beforeEach((_to, _from, next) => {
      if (this.mobile_menu) {
        this.mobile_menu = false;
      }
      next();
    });
  },

  watch: {
    mobile_menu(val) {
      if (val) {
        document.body.classList.add("menu-open");
      } else {
        document.body.classList.remove("menu-open");
      }
    },
  },
};
</script>
