import { createWebHistory, createRouter } from "vue-router";

import NotFound from "@view/not-found/NotFound";
import HomePage from "@view/home-page/HomePage";
import OurTools from "@view/our-tools/OurTools";
import AppSkills from "@view/app-skills/AppSkills";
import AppProcess from "@view/app-process/AppProcess";
import AppWork from "@view/app-work/AppWork";
import AppBlog from "@view/app-blog/AppBlog";
import HireMe from "@view/hire-me/HireMe";
import CreateBlog from "@view/create-blog/CreateBlog";
import ReadBlog from "@view/read-blog/ReadBlog";


const routes = [
    {
        path: '/',
        component: HomePage
    },
    {
        path: '/tools',
        component: OurTools
    },
    {
        path: '/skills',
        component: AppSkills
    },
    {
        path: '/process',
        component: AppProcess
    },
    {
        path: '/work',
        component: AppWork
    },
    {
        path: '/blog',
        component: AppBlog
    },
    {
        path: '/blog/create',
        component: CreateBlog
    },
    {
        path: '/blog/:id',
        name: 'read_blog',
        component: ReadBlog
    },
    {
        path: '/hire-joemags',
        name: 'hire',
        component: HireMe
    },
    {
        path: '/:pathMatch(.*)',
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;