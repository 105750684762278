<template>
  <section
    class="container mx-auto flex items-center flex-col-reverse md:flex-row md:grid-cols-2 gap-3"
  >
    <div class="absolute inset-0 -z-10  w-full">
      <img
        src="../../assets/img/bg2.png"
        class="object-fill w-full mx-0 opacity-70"
        alt=""
      />
    </div>
    <div class="w-full overflow-hidden max-w-lg">
      <img
        alt="feature"
        class="object-cover object-center w-full"
        src="../../assets/img/hero.png"
      />
    </div>
    <div class="p-4 sm:p-8 rounded-md mt-12">
      <h2 class="font-black text-gray-300 text-2xl">
        You've got an Idea?
      </h2>
      <h4 class="text-6xl tracking-tight">
        I've got the <span class="font-semibold text-blue-700">Skills</span>
      </h4>
      <p class="mt-4">
        Hi, I'm <strong>Joemags</strong>, a Zimbabwean full-stack software
        developer. Welcome to my space<br />
        Let's build the app of your dreams.
      </p>
      <button @click="$router.push({name: 'hire'})" class="w-full focus:outline-none bg-blue-700 rounded mt-16 py-3">
        Hire Me
      </button>
    </div>
  </section>
</template>
<style src="./AppHero.css" scoped></style>
<script>
export default {
  name: "app-hero",
  data() {
    return {};
  },
};
</script>
