import firebase from "firebase/app";
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// firebase init
const firebaseConfig = {
    apiKey: "AIzaSyCJ9AuZC2s5evg6fI55Ui3jP-V2OFS163M",
    authDomain: "joemags-apps.firebaseapp.com",
    projectId: "joemags-apps",
    storageBucket: "joemags-apps.appspot.com",
    messagingSenderId: "1054505144820",
    appId: "1:1054505144820:web:3bdb8fac35c5969675e9a1",
    measurementId: "G-QWZ0B4BD90"
}

firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage();
const fieldValue = firebase.firestore.FieldValue;

// collection references
const blogs = db.collection('blogs')
// export utils/refs
export {
  db,
  auth,
  blogs,
  storage,
  fieldValue
}