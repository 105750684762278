<template>
  <section :class="view ? 'inner-view' : 'view'">
    <h2 class="w-full text-center font-bold text-xl tracking-wide mt-4 mb-6">
      Previous Work
    </h2>
    <div
      ref="work"
      class="grid w-full overflow-x-auto gap-4 grid-flow-col work relative"
    >
      <article
        v-for="i in 10"
        :key="i"
        class="w-72 h-64 overflow-hidden transform hover:scale-x-105 transition duration-1000 relative my-4 mx-2 hover:shadow-md flex flex-col cursor-pointer"
      >
        <div class="h-64 overflow-hidden">
          <img
            alt="content"
            class="object-cover mx-1 pb-16 absolute inset-0 h-full w-full transform hover:scale-125 transition duration-500"
            src="../../assets/img/work.jpg"
          />
        </div>
        <div class="px-5 py-2 z-30 pointer-events-none bg-dark-gray">
          <h2 class="font-bold tracking-wide text-lg uppercase">Thri Tech</h2>
          <header class="font-medium">Distributed point of sell {{ i }}</header>
        </div>
      </article>
    </div>
    <div class="container right-0 left-0 pl-4 mx-auto absolute -mt-48 pointer-events-none">
      <div class="flex justify-between pr-6">
        <button
          @click="scroll(-1)"
          class="rounded-full pointer-events-auto bg-white p-4 mx-2 focus:outline-none shadow-lg"
        >
          <svg
            class="w-5 text-gray-900"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            />
          </svg>
        </button>
        <button
          @click="scroll(1)"
          class="rounded-full pointer-events-auto bg-white p-4 mx-2 focus:outline-none shadow-lg"
        >
          <svg
            class="w-5 text-gray-900"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </button>
      </div>
    </div>
  </section>
</template>
<style src="./AppWork.css" scoped></style>
<script>
export default {
  props: ["view"],
  name: "app-work",
  data() {
    return {};
  },
  methods: {
    scroll(dir) {
      this.$nextTick(() => {
        let scrollAmount = 0;
        var slideTimer = setInterval(() => {
          if (dir == 1) {
            this.$refs.work.scrollLeft += 35;
          } else {
            this.$refs.work.scrollLeft -= 35;
          }
          scrollAmount += 10;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      });

      // this.$refs.work.scrollRight += 20;
    },
  },
};
</script>
