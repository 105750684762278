<template>
  <div class="views">
    <app-hero></app-hero>
    <section class="w-full border-b-2 my-12 border-gray-800"></section>
    <our-tools view="true"></our-tools>
    <app-skills view="true"></app-skills>
    <app-work view="true"></app-work>
    <app-blog view="true"></app-blog>
  </div>
</template>
<style src="./HomePage.css" scoped></style>
<script>
import AppHero from "@view/app-hero/AppHero";
import OurTools from "@view/our-tools/OurTools";
import AppSkills from "@view/app-skills/AppSkills";
import AppWork from "@view/app-work/AppWork";
import AppBlog from "@view/app-blog/AppBlog";


export default {

  name: "home-page",
  components: {
    AppHero,
    OurTools,
    AppSkills,
    AppWork,
    AppBlog
  },
  mounted() {},
};
</script>
