<template>
  <section class="relative">
    <nav-bar></nav-bar>
    <!-- Navigation Here -->
    <router-view></router-view>
    <app-footer></app-footer>
  </section>
</template>

<script>
import NavBar from "~/components/nav-bar/NavBar";
import AppFooter from "~/components/app-footer/AppFooter";
export default {
  name: "App",
  components: {
    NavBar,
    AppFooter
  },
};
</script>