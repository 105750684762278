<template>
  <section class="view pt-2">
    <div class="flex justify-between items-center mb-4">
      <h2 class="font-bold uppercase text-2xl">
        Create A Post
      </h2>
      <button
        @click="publish"
        class="font-medium bg-green-600 rounded text-lg px-4 py-2 focus:outline-none"
      >
        Publish
      </button>
    </div>
    <div class="w-full my-2">
      <label for="title">Post Title</label>
      <input type="text" v-model.trim="title" name="title" id="title" placeholder="Enter post title" class="w-full rounded bg-dark-gray">
    </div>
    <div class="w-full mb-5">
      <label for="cover">Select Cover Image</label>
      <input
        class="w-full px-1 py-2 mt-2 rounded text-white bg-dark-gray border "
        type="file"
        name="cover_image"
        @change="upload"
        id="cover_image"
      />
    </div>
    <h2 class="font-bold tracking-wide mb-2">Compose an epic...</h2>
    <div ref="editor" class="min-h-20"></div>
  </section>
</template>
<style src="./CreateBlog.css" scoped></style>
<script>
import Quill from "quill";
import { nanoid } from "nanoid";
import S from "string";
import imageUpload from "quill-plugin-image-upload";
import { blogs, fieldValue, storage } from "~/utils/firebase";
import { toastr } from "~/utils/swal";
// register quill-plugin-image-upload
Quill.register("modules/imageUpload", imageUpload);

export default {
  name: "create-blog",
  components: {},
  mounted() {
    const article = blogs.doc();
    this.article_id = article.id;
    const images = storage.ref(`blog/${article.id}`);
    const toolbar = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["blockquote", "code-block"],
      [{ align: [] }],
      ["link", "image"],
    ];
    const options = {
      // debug: "info",
      modules: {
        toolbar,
        imageUpload: {
          upload: (file) => {
            return new Promise((resolve, reject) => {
              const ext = file.name.split(".").pop();
              const name = `${nanoid(11)}.${ext}`;
              console.log(name);
              images
                .child(name)
                .put(file)
                .then(async (saved) => {
                  const download = await saved.ref.getDownloadURL();
                  return resolve(download);
                })
                .catch((err) => {
                  console.error(err);
                });
            });
          },
        },
      },
      theme: "snow",
    };
    this.$nextTick(() => {
      this.quill = new Quill(this.$refs.editor, options);
    });
  },
  data() {
    return {
      quill: null,
      uploading_cover: false,
      cover_image: null,
      title: ""
    };
  },
  methods: {
    upload({
      target: {
        files: [file],
      },
    }) {
      if (!file) return;
      this.uploading_cover = true;
      const images = storage.ref(`blog/${this.article_id}`);
      images
        .child("cover")
        .put(file)
        .then(async (saved) => {
          const download = await saved.ref.getDownloadURL();
          this.uploading_cover = true;
          console.log(download);
          this.cover_image = download;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    publish() {
      if (!this.cover_image || !this.title) {
        return toastr.error("Cover image and title field are required");
      }
      console.log(this.article_id);
      const html = this.quill.root.innerHTML;
      if (html.length < 10) {
        return toastr.error("Please enter a little longer article");
      }
      const id = S(`${this.title}--${this.article_id}`).slugify().s;
      console.log({id});
      const post = {
        html,
        cover_image: this.cover_image,
        date_created: fieldValue.serverTimestamp(),
        date_updated: fieldValue.serverTimestamp(),
        title: this.title,
        id,
        excerpt: this.quill.getText(0, 80)
      };
      console.log(html, { post });
      blogs
        .doc(this.article_id)
        .set(post)
        .then(() => {
          this.$router.push({ name: "read_blog", params: { id } });
        })
        .catch((err) => {
          console.log(err);
          toastr.error("Error saving your post. Try again");
        });
    },
  },
};
</script>
