<template>
  <section :class="view ? 'inner-view' : 'view'">
    <h2 class="w-full text-center font-bold text-xl tracking-wide mt-4 mb-6">
      Excited to work on your project.
    </h2>
    <div class="flex flex-col sm:flex-row items-center justify-between">
      <div class="max-w-lg px-16 sm:px-24 text-blue-600">
        <svg
          class="w-16 sm:w-24"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
      </div>
      <section class="grid grid-cols-1 gap-5 flex-1">
        <div>
          <label for="email">Phone</label>
          <input
            type="tel"
            name="email"
            id="email"
            class="w-full bg-dark-gray rounded-sm"
          />
        </div>
        <div>
          <label for="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            class="w-full bg-dark-gray rounded-sm"
          />
        </div>
        <div>
          <label for="message">Brief me about the project.</label>
          <textarea
            name="message"
            id="message"
            class="h-32 w-full bg-dark-gray rounded-sm"
          ></textarea>
        </div>
        <div class="text-center">
          <button class="py-3 px-4 focus:outline-none">
            Submit
          </button>
        </div>
      </section>
    </div>
  </section>
</template>
<style src="./HireMe.css" scoped></style>
<script>
export default {
  props: ["view"],
  name: "hire-me",
  data() {
    return {};
  },
};
</script>
