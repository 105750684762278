<template>
  <section :class="view ? 'inner-view' : 'view'">
    <h2 class="w-full text-center font-bold text-2xl tracking-wide mb-10">
      This magician reveals his secrets.
    </h2>
    <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <div class="shadow-ld bg-gray-900 rounded-md hover:shadow-lg">
        <a
          href="javascript:;"
          class="hover:text-red-700 w-full font-bold flex flex-col items-center p-8"
        >
          <div>
            <svg
              class="w-16 block"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M504.4 115.83a5.72 5.72 0 00-.28-.68 8.52 8.52 0 00-.53-1.25 6 6 0 00-.54-.71 9.36 9.36 0 00-.72-.94c-.23-.22-.52-.4-.77-.6a8.84 8.84 0 00-.9-.68L404.4 55.55a8 8 0 00-8 0L300.12 111a8.07 8.07 0 00-.88.69 7.68 7.68 0 00-.78.6 8.23 8.23 0 00-.72.93c-.17.24-.39.45-.54.71a9.7 9.7 0 00-.52 1.25c-.08.23-.21.44-.28.68a8.08 8.08 0 00-.28 2.08v105.24l-80.22 46.19V63.44a7.8 7.8 0 00-.28-2.09c-.06-.24-.2-.45-.28-.68a8.35 8.35 0 00-.52-1.24c-.14-.26-.37-.47-.54-.72a9.36 9.36 0 00-.72-.94 9.46 9.46 0 00-.78-.6 9.8 9.8 0 00-.88-.68L115.61 1.07a8 8 0 00-8 0L11.34 56.49a6.52 6.52 0 00-.88.69 7.81 7.81 0 00-.79.6 8.15 8.15 0 00-.71.93c-.18.25-.4.46-.55.72a7.88 7.88 0 00-.51 1.24 6.46 6.46 0 00-.29.67 8.18 8.18 0 00-.28 2.1v329.7a8 8 0 004 6.95l192.5 110.84a8.83 8.83 0 001.33.54c.21.08.41.2.63.26a7.92 7.92 0 004.1 0c.2-.05.37-.16.55-.22a8.6 8.6 0 001.4-.58L404.4 400.09a8 8 0 004-6.95V287.88l92.24-53.11a8 8 0 004-7V117.92a8.63 8.63 0 00-.24-2.09zM111.6 17.28l80.19 46.15-80.2 46.18-80.18-46.17zm88.25 60V278.6l-46.53 26.79-33.69 19.4V123.5l46.53-26.79zm0 412.78L23.37 388.5V77.32L57.06 96.7l46.52 26.8v215.18a6.94 6.94 0 00.12.9 8 8 0 00.16 1.18 5.92 5.92 0 00.38.9 6.38 6.38 0 00.42 1 8.54 8.54 0 00.6.78 7.62 7.62 0 00.66.84c.23.22.52.38.77.58a8.93 8.93 0 00.86.66l92.19 52.18zm8-106.17l-80.06-45.32 84.09-48.41 92.26-53.11 80.13 46.13-58.8 33.56zm184.52 4.57L215.88 490.11V397.8l130.72-74.6 45.77-26.15zm0-119.13L358.68 250l-46.53-26.79v-91.42l33.69 19.4L392.37 178zm8-105.28l-80.2-46.17 80.2-46.16 80.18 46.15zm8 105.28V178L455 151.19l33.68-19.4v91.39z"
              />
            </svg>
          </div>
          <h2 class="block text-gray-200 mt-3 text-xl">Laravel</h2>
        </a>
      </div>
      <div class="shadow-ld bg-gray-900 rounded-md hover:shadow-lg">
        <a
          href="javascript:;"
          class="hover:text-green-700 w-full font-bold flex flex-col items-center p-8"
        >
          <div>
            <svg
              class="w-16"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M356.9 64.3H280l-56 88.6-48-88.6H0L224 448 448 64.3h-91.1zm-301.2 32h53.8L224 294.5 338.4 96.3h53.8L224 384.5 55.7 96.3z"
              />
            </svg>
          </div>
          <h2 class="block text-gray-200 mt-3 text-xl">VueJs</h2>
        </a>
      </div>
      <div class="shadow-ld bg-gray-900 rounded-md hover:shadow-lg">
        <a
          href="javascript:;"
          class="hover:text-blue-700 w-full font-bold flex flex-col items-center p-8"
        >
          <div class="flex items-center justify-between">
            <svg
              class="w-16"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 153.9A102.1 102.1 0 10358.1 256 102.23 102.23 0 00256 153.9z"
              />
              <circle fill="currentColor" cx="402.59" cy="116.45" r="46.52" />
              <path
                fill="currentColor"
                d="M459.86 163.2l-1.95-4.28-3.11 3.52a70 70 0 01-28.06 19.32l-3 1.1 1.22 2.93A181.43 181.43 0 01439 256c0 100.92-82.1 183-183 183S73 356.92 73 256 155.08 73 256 73a180.94 180.94 0 0178.43 17.7l2.87 1.3 1.25-2.92A70.19 70.19 0 01359.21 62l3.67-2.93-4.17-2.07A221.61 221.61 0 00256 32C132.49 32 32 132.49 32 256s100.49 224 224 224 224-100.49 224-224a222.19 222.19 0 00-20.14-92.8z"
              />
            </svg>
            <span class="mx-4">&amp;</span>
            <svg
              class="w-16"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M185.7 268.1h76.2l-38.1-91.6-38.1 91.6zM223.8 32L16 106.4l31.8 275.7 176 97.9 176-97.9 31.8-275.7zM354 373.8h-48.6l-26.2-65.4H168.6l-26.2 65.4H93.7L223.8 81.5z"
              />
            </svg>
          </div>
          <h2 class="block text-gray-200 mt-3 text-xl">Ionic & Angular</h2>
        </a>
      </div>
      <div class="shadow-ld bg-gray-900 rounded-md hover:shadow-lg">
        <a
          href="javascript:;"
          class="hover:text-purple-700 w-full font-bold flex flex-col items-center p-8"
        >
          <div>
            <svg
              class="w-16"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                fill="currentColor"
                d="M277 170.7A85.35 85.35 0 00277 0H106.3a85.3 85.3 0 000 170.6 85.35 85.35 0 000 170.7 85.35 85.35 0 1085.3 85.4v-256zm0 0a85.3 85.3 0 1085.3 85.3 85.31 85.31 0 00-85.3-85.3z"
              />
            </svg>
          </div>
          <h2 class="block text-gray-200 mt-3 text-xl">Figma</h2>
        </a>
      </div>
      <div class="shadow-ld bg-gray-900 rounded-md hover:shadow-lg">
        <a
          href="javascript:;"
          class="hover:text-blue-300 w-full font-bold flex flex-col items-center p-8"
        >
          <div>
            <svg
              class="w-16"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M86.76 255a9.89 9.89 0 004.87-1.29 9.82 9.82 0 003.65-13.43c-16.46-28.56-17.81-52.12-7.45-70 14.26-24.57 53.61-33.65 105.27-24.29a9.86 9.86 0 0011.45-7.9 9.84 9.84 0 00-7.93-11.44c-29.19-5.28-56-5.18-77.39.3-22.3 5.71-39 17.28-48.45 33.48-14 24.19-12.7 54.73 7.42 89.62a9.85 9.85 0 008.56 4.95zm274.85-111.27c32.24.42 52.61 9.31 62.79 26.86 14.21 24.48 2.52 62.81-31.27 102.52a9.82 9.82 0 007.51 16.18 9.88 9.88 0 007.52-3.46c19.12-22.47 32.35-45.54 38.25-66.71 6.14-22 4.43-42.21-5-58.38-13.8-23.78-40.13-36.15-79.59-36.67h-.14a9.83 9.83 0 00-.12 19.66zm-35.14 271.16a9.88 9.88 0 00-13.5 3.35c-16.41 27.15-36.57 42.1-56.77 42.1-28.49 0-56-29.31-73.73-78.42a9.87 9.87 0 00-12.59-5.92 9.83 9.83 0 00-6 12.58c10 27.77 23.47 50.75 39 66.46 16.11 16.34 34.55 25 53.32 25 27.38 0 53.54-18.33 73.65-51.61a9.81 9.81 0 00-3.38-13.5zm105.23-76.35a32.14 32.14 0 00-29.9 44.33c-41.8 19.5-119.8 4.79-191.87-36.62-32.91-18.9-62.16-41.86-84.6-66.39a9.9 9.9 0 00-13.91-.65 9.8 9.8 0 00-.65 13.9c23.79 26 54.68 50.28 89.33 70.18 40.28 23.13 82.27 38.63 121.43 44.81a225.54 225.54 0 0035 2.91c23.12 0 43-4.3 58.51-12.79a32.2 32.2 0 1016.7-59.68zm0 44.66a12.6 12.6 0 01-7.82-2.72 10 10 0 00-2.2-2.21 12.61 12.61 0 1110 4.93z"
              />
              <path
                fill="currentColor"
                d="M82.09 338.59c.57-21.26 12.41-47 33.68-73.16 23.19-28.45 56.69-56 94.34-77.65 33.25-19.1 65.2-31.9 98.07-38.91a9.83 9.83 0 10-4.12-19.22c-34.85 7.43-68.78 21-103.79 41.09C116.09 219.09 59.9 289.88 62.46 343.9a32.32 32.32 0 1019.63-5.31zM80.3 383.2a12.5 12.5 0 1112.59-12.5 12.56 12.56 0 01-12.59 12.5z"
              />
              <path
                fill="currentColor"
                d="M256.2 96.32a32.23 32.23 0 0026.53-13.81c17.89 11.69 34 35 45.81 66.12 13 34.39 19.84 75.38 19.84 118.54 0 37.18-5.19 72.35-15 103.6a9.72 9.72 0 00.66 7.49 9.82 9.82 0 005.8 4.84 9.89 9.89 0 0012.34-6.44c10.42-33.14 15.93-70.34 15.93-109.49 0-47.17-7.77-91.77-22.47-129-14.41-36.48-34.13-62.4-57.14-75.16a32.3 32.3 0 10-32.3 33.31zm0-44.66a12.5 12.5 0 11-12.59 12.5 12.56 12.56 0 0112.59-12.5zm-5.2 191.7a24.35 24.35 0 005.16 48.16 24.68 24.68 0 005.16-.55A24.36 24.36 0 10251 243.36z"
              />
            </svg>
          </div>
          <h2 class="block text-gray-200 mt-3 text-xl">ElectronJs</h2>
        </a>
      </div>
      <div class="shadow-ld bg-gray-900 rounded-md hover:shadow-lg">
        <a
          href="javascript:;"
          class="hover:text-flutter w-full font-bold flex flex-col items-center p-8"
        >
          <div>
            <svg
              class="w-16"
              viewBox="0.29 0.22 77.26 95.75"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fill-rule="evenodd">
                <path
                  d="M48.75 95.97L22.84 70.23l14.32-14.57 40.39 40.31z"
                  fill-opacity=".7"
                  fill="currentColor"
                />
                <g fill="currentColor">
                  <path
                    d="M22.52 70.25L48.2 44.57h28.87L37.12 84.52z"
                    fill-opacity=".85"
                  />
                  <path d="M.29 47.85l14.58 14.57L77.07.22H48.05z" />
                </g>
              </g>
            </svg>
          </div>
          <h2 class="block text-gray-200 mt-3 text-xl">Flutter</h2>
        </a>
      </div>
    </div>
  </section>
</template>
<style src="./OurTools.css" scoped></style>
<script>
export default {
  props: ['view'],
  name: "our-tools",
  data() {
    return {};
  },
};
</script>
