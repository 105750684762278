import { nanoid } from "nanoid";
import router from "~/router";
import { auth, blogs } from "~/utils/firebase";
import { toastr } from "~/utils/swal";
import S from "string";

export default {

  async login({ dispatch }, { email, password }) {
    // sign user in
    const { user } = await auth.signInWithEmailAndPassword(email, password)
    // fetch user profile and set in state
    toastr.success('Logged in successfully')

    dispatch('fetchUserProfile', user)
  },

  /**
   * Fetch user profile and save it to state
   * Check for data streams that needed to be listened
   * @param {*} param0 
   * @param {firebase.User} user 
   */
  // async fetchUserProfile({ commit, dispatch }, user) {
  //   const userProfile = await users.doc(user.uid).get()
  //   let profile = {
  //     ...userProfile.data(),
  //     uid: user.uid,
  //     email: user.email
  //   }
  //   console.log(profile);

  //   // set user profile in state
  //   commit('setUserProfile', profile)
  //   // change route to dashboard
  //   const { path } = router.currentRoute.value;
  //   if (path === '/login' || path === '/signup') {
  //     router.push('/')
  //   }

  //   //Stream flower if user belong to a flower
  //   if (profile.flower_id) {
  //     dispatch('streamFlower', profile.flower_id);
  //   } else {
  //     //Does not belong to any flower
  //     //stream invites
  //     dispatch('listenToFlowerInvites', profile);
  //   }

  //   dispatch('streamUserState', profile);

  // },


  /**
   * User Authentication
   * @param {*} param0 
   * @param {*} form 
   */
  // async signup({ dispatch }, form) {
  //   // sign user up
  //   const { user } = await auth.createUserWithEmailAndPassword(form.email, form.password)
  //   // create user profile object in userCollections

  //   await user.updateProfile({
  //     displayName: form.country
  //   });

  //   await users.doc(user.uid).set({
  //     name: form.name,
  //     code: nanoid(6).toUpperCase(),
  //     country: form.country,
  //     state: GET_INVITE //Change to Pending until flower breaks then set to get_invite
  //   });

  //   toastr.success("Signed up successfully")

  //   // fetch user profile and set in state
  //   dispatch('fetchUserProfile', user)
  // },
  async logout({ commit }) {
    await auth.signOut();

    toastr.success('Logged out successfully')
    // clear userProfile and redirect to /login
    commit('setUserProfile', {})
    // router.push('/login')
    location.reload();
  },

  /**
   * Fetch user document by code:
   * First sets the document in `can_query` which is the document that 
   * we use to validate document.data.code === /databases/$(database)/documents/can_query/$(request.auth.uid)).data.code (rule toBeFixed)
   * then if that's valid return document data.
   * So that we set a rule on the main user not to fetch all the users
   * @param {*} param0 
   */
  // getUserByCode(_x, { code, uid }) {
  //   return new Promise((resolve, reject) => {
  //     can_query.doc(uid)
  //       .set({ [code]: true }, { merge: true }) //Use in rules collection
  //       .then(() => {
  //         return users.where("code", "==", code).get();
  //       })
  //       .then((snap) => {
  //         if (snap.empty) return reject('user_not_found');
  //         const data = snap.docs[0].data();
  //         data.id = snap.docs[0].id;
  //         return resolve(data);
  //       })
  //   })
  // },

  // async streamFlower({ commit }, flower_id) {
  //   const flower = (await flowers.doc(flower_id).get()).data();
  //   flowers.doc(flower_id).collection('petals')
  //     .orderBy('position')
  //     .onSnapshot((snap) => {
  //       const petals = [];
  //       snap.forEach((doc) => {
  //         const petal = { ...doc.data(), ...flower };
  //         petals.push(petal);
  //       });
  //       commit('petals', petals);
  //       // console.log(petals);
  //     });
  // },

  // async updateStateToInvited({ dispatch }, flower_name) {
  //   const user = auth.currentUser;
  //   await users.doc(user.uid).update({
  //     state: INVITED
  //   });
  //   toastr.success(`You were added to ${flower_name}. Complete your payment`)
  //   // fetch user profile and set in state
  //   dispatch('fetchUserProfile', user)
  // },

  // async updatedStateToWaiting({ dispatch }) {
  //   const user = auth.currentUser;
  //   await users.doc(user.uid).update({
  //     state: WAITING
  //   });
  //   toastr.success(`You have completed invite process.`)
  //   // fetch user profile and set in state
  //   dispatch('fetchUserProfile', user)
  // },

  /**
   * TODO; Watch state changes on user to 
   * refresh page and switch buttons
   * @param {*} param0 
   */
  // streamUserState({ commit }, {uid, state}) {
  //   return users.doc(uid)
  //     .onSnapshot((snap) => {
  //       if(!snap.exists) return;
  //       commit('STATE', state);
  //       console.log(state);
  //     })
  // },


  fetchBlogs({ commit, dispatch }, params) {
    
    blogs.limit(20).orderBy('date_created', 'desc').get()
      .then((snap) => {
        console.log(snap.size);
        const posts = [];
        snap.forEach(doc => {
          const data = doc.data();
          data.id =  S(`${data.title} ${doc.id}`).slugify().s;
          data.date_created = data.date_created.toDate();
          posts.push(data);
        });
        commit('blogs', posts);
      })
      .catch((err) => {
        console.log(err);
      })
  },

  getBlog({state: {blogs}}, id){
    console.log(blogs);
    return {}
  }
};
