import Swal from 'sweetalert2';

const toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});


const toastr = {
    success(title) {
        toast.fire({
            icon: 'success',
            title
        })
    },
    error(title) {
        toast.fire({
            icon: 'error',
            title
        })
    },
    warning(title) {
        toast.fire({
            icon: 'warning',
            title
        })
    },
    info(title) {
        toast.fire({
            icon: 'info',
            title
        })
    }
}

export {
    toastr
}