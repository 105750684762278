import { createApp } from 'vue'
import Root from './App.vue'
import router from './router';
import moment from "moment";
import { auth } from './utils/firebase';


import "~/assets/css/tailwind.css";
import { store } from './store';

let app;

auth.onAuthStateChanged((user) => {
    if (!app) {
        app = createApp(Root);
        app.use(router);
        app.use(store);
        // app.config.globalProperties.$swal = Swal;
        app.config.globalProperties.$format_date = (date) => {
            return moment(date).format('ll'); 
        }
        app.mount('#app');
    }
    store.dispatch('fetchBlogs');
})
